import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
          <path d="M5413 7210 c-13 -5 -23 -11 -23 -14 0 -7 40 6 46 15 6 10 6 10 -23
-1z"/>
        <path d="M3957 8233 c3 -93 10 -172 15 -178 5 -5 89 -17 186 -26 278 -26 520
-49 737 -70 110 -11 297 -29 415 -39 118 -11 379 -36 580 -55 201 -20 405 -39
453 -42 56 -4 90 -10 93 -18 6 -15 -20 -73 -210 -468 -42 -87 -76 -160 -76
-161 0 -2 -58 -122 -129 -267 -180 -369 -291 -599 -374 -774 -39 -82 -114
-238 -167 -345 -53 -107 -112 -226 -130 -265 -45 -97 -131 -274 -245 -510 -54
-110 -114 -236 -135 -280 -21 -44 -81 -169 -134 -278 -53 -109 -96 -203 -96
-208 0 -12 211 -12 514 1 271 11 990 35 1531 50 461 13 545 16 550 21 4 4 -17
237 -30 336 -4 26 -11 41 -18 38 -7 -2 -221 -6 -477 -10 -256 -3 -676 -10
-935 -15 -258 -6 -473 -6 -477 -2 -7 7 14 57 112 267 95 201 150 325 150 334
0 6 5 11 10 11 6 0 10 5 10 12 0 6 23 61 51 122 48 104 68 148 153 329 19 39
46 99 61 132 15 33 42 92 60 130 18 39 47 102 65 140 17 39 44 97 60 130 96
207 161 347 183 395 14 30 46 99 71 153 25 54 46 99 46 101 0 2 29 65 65 140
36 75 65 138 65 140 0 2 38 82 84 178 46 95 105 220 131 278 48 108 88 194
155 335 20 44 35 83 31 86 -3 4 -123 18 -266 33 -143 14 -318 33 -390 41 -183
20 -481 52 -750 80 -271 29 -661 70 -795 85 -495 54 -741 80 -772 80 l-36 0 5
-167z"/>
<path d="M3978 7510 c3 -176 3 -180 25 -184 22 -4 121 -11 632 -46 489 -34
700 -51 709 -60 4 -5 -20 -61 -54 -127 -34 -65 -119 -228 -188 -363 -69 -135
-128 -247 -131 -250 -3 -3 -30 -52 -58 -110 -60 -119 -234 -457 -340 -660 -86
-164 -192 -369 -286 -550 -85 -165 -204 -394 -267 -515 -26 -49 -91 -178 -145
-285 -54 -107 -101 -197 -104 -200 -3 -3 -23 -39 -43 -80 -21 -41 -75 -147
-121 -235 -157 -301 -267 -517 -267 -522 0 -7 142 -1 590 27 518 31 969 58
1335 80 187 11 484 29 660 40 176 11 439 26 585 35 146 8 328 20 405 25 77 5
192 12 255 15 63 3 144 8 179 11 l63 6 -7 84 c-4 46 -12 132 -19 191 l-12 108
-89 -3 c-108 -3 -1563 -67 -1855 -82 -113 -5 -315 -15 -450 -20 -135 -6 -388
-17 -564 -26 -175 -9 -322 -12 -326 -7 -11 12 94 225 1017 2073 452 905 820
1646 818 1648 -4 4 -153 19 -375 37 -107 8 -242 20 -300 25 -58 6 -189 17
-292 25 -272 22 -529 43 -718 60 -91 8 -187 15 -215 15 l-50 0 3 -180z"/>
<path d="M7157 3359 c-105 -9 -117 -13 -117 -36 0 -16 7 -18 40 -15 l41 4 -7
-43 c-3 -24 -12 -76 -20 -116 -18 -93 -18 -95 9 -91 20 3 24 13 40 106 25 144
29 152 72 152 23 0 38 6 42 16 10 26 -8 30 -100 23z"/>
<path d="M6884 3336 c-17 -7 -40 -26 -52 -41 -40 -51 -24 -96 44 -126 37 -15
45 -23 42 -41 -2 -13 -15 -29 -32 -37 -25 -12 -33 -11 -64 3 -39 19 -41 19
-44 -14 -4 -31 34 -46 87 -36 50 10 105 63 105 103 0 38 -7 46 -62 72 -52 25
-58 39 -30 64 20 18 82 23 82 7 0 -5 4 -10 10 -10 9 0 26 47 19 55 -13 12 -78
13 -105 1z"/>
<path d="M6650 3321 l-55 -6 -24 -143 c-13 -78 -22 -144 -20 -145 2 -2 42 -1
89 2 80 6 85 7 88 30 4 22 2 23 -60 20 l-63 -4 3 30 c5 51 9 55 61 55 35 0 51
4 55 16 10 27 -4 35 -50 28 -43 -6 -44 -6 -44 22 0 45 11 53 74 56 50 3 60 6
67 26 8 20 6 22 -29 20 -20 -1 -62 -4 -92 -7z"/>
<path d="M6370 3303 c-14 -3 -28 -8 -32 -11 -6 -6 -48 -243 -48 -272 0 -5 9
-10 20 -10 14 0 21 9 25 38 10 58 11 59 55 65 97 13 159 108 110 170 -22 27
-57 33 -130 20z m74 -39 c10 -4 16 -18 16 -39 0 -39 -40 -78 -74 -73 -18 3
-21 9 -18 47 4 64 27 84 76 65z"/>
<path d="M6113 3278 c-11 -15 -52 -88 -117 -210 -42 -80 -44 -88 -26 -88 17 0
51 40 63 74 1 4 31 10 65 13 l62 5 0 -37 c0 -34 2 -36 28 -33 15 2 26 8 25 13
-39 247 -46 275 -68 275 -13 0 -27 -6 -32 -12z m31 -104 c7 -57 3 -64 -39 -64
-34 0 -34 0 -23 33 13 38 37 77 48 77 4 0 10 -21 14 -46z"/>
<path d="M5877 3270 c-43 -4 -80 -9 -81 -11 -5 -5 -46 -259 -46 -280 0 -13 11
-14 81 -7 90 8 99 12 99 36 0 15 -9 17 -65 14 l-65 -4 7 34 c8 43 6 41 66 53
37 8 53 16 55 29 3 16 -2 18 -47 15 -48 -4 -51 -2 -51 19 0 50 16 62 80 62 57
0 60 1 60 25 0 14 -3 24 -7 24 -5 -1 -43 -5 -86 -9z"/>
<path d="M5668 3253 c-12 -3 -19 -19 -24 -59 -3 -30 -9 -56 -13 -58 -3 -2 -37
-7 -75 -11 l-69 -7 8 47 c3 26 9 54 12 61 4 10 -3 14 -21 14 -29 0 -28 3 -51
-140 -9 -52 -18 -107 -21 -122 -5 -25 -3 -28 19 -28 23 0 26 5 32 55 9 66 21
75 100 75 l57 0 -7 -37 c-3 -21 -9 -48 -12 -60 -4 -19 -1 -23 20 -23 23 0 25
5 46 118 12 64 24 129 27 144 6 28 -2 37 -28 31z"/>
<path d="M5245 3211 c-96 -45 -136 -214 -62 -266 44 -31 157 -5 165 39 3 16 0
18 -19 11 -13 -4 -44 -10 -68 -12 -52 -6 -61 4 -61 65 0 109 68 163 161 127
20 -8 35 35 16 47 -22 14 -91 8 -132 -11z"/>
<path d="M5055 3198 c-2 -7 -9 -53 -16 -101 -7 -48 -17 -86 -22 -84 -5 1 -30
44 -56 95 -41 81 -49 91 -73 92 -16 0 -28 -4 -28 -9 0 -5 -11 -71 -25 -145
-14 -74 -25 -138 -25 -141 0 -11 41 -4 45 8 3 6 11 52 18 101 7 49 17 91 22
92 6 2 31 -38 56 -89 38 -77 50 -92 72 -95 23 -3 27 1 33 35 3 21 15 81 25
133 23 118 23 120 -1 120 -11 0 -22 -6 -25 -12z"/>
<path d="M3537 3192 c-61 -3 -69 -5 -79 -28 -6 -13 -9 -26 -7 -28 2 -3 42 0
87 6 46 5 86 7 89 4 7 -7 -21 -44 -144 -194 -51 -62 -93 -122 -93 -134 0 -18
5 -19 48 -14 26 3 83 8 127 12 76 6 95 14 95 41 0 12 -72 11 -152 -2 -15 -2
-29 -1 -32 3 -3 5 48 74 113 154 65 81 121 156 124 168 5 20 2 21 -51 18 -31
-2 -88 -4 -125 -6z"/>
<path d="M4711 3103 c-21 -141 -42 -173 -110 -173 -48 0 -53 23 -32 136 20
103 19 114 -4 114 -19 0 -22 -7 -40 -91 -17 -87 -21 -150 -10 -168 15 -25 69
-40 108 -31 84 18 106 51 131 185 20 114 20 115 -7 115 -21 0 -24 -7 -36 -87z"/>
<path d="M4340 3161 c-59 -5 -65 -8 -68 -30 -3 -22 -1 -23 31 -17 20 4 38 3
40 -1 3 -4 -4 -53 -14 -108 -24 -133 -24 -137 3 -133 19 2 24 12 31 53 5 28
15 83 24 123 l15 72 38 0 c36 0 50 12 50 41 0 9 -41 9 -150 0z"/>
<path d="M4167 3108 c-41 -63 -74 -101 -80 -92 -2 5 -12 35 -21 67 -14 50 -20
57 -41 57 -14 0 -25 -3 -25 -6 0 -3 14 -45 31 -92 29 -84 31 -111 13 -174 -4
-14 1 -18 19 -18 25 0 37 21 37 66 0 21 109 196 130 209 18 11 11 25 -13 25
-16 0 -31 -13 -50 -42z"/>
<path d="M3836 3105 c-9 -13 -16 -29 -16 -34 0 -5 -6 -16 -13 -23 -16 -17
-120 -218 -115 -223 13 -13 49 12 60 41 13 37 43 49 109 42 30 -3 34 -7 37
-35 2 -25 8 -33 22 -33 10 0 21 4 24 8 4 7 -25 198 -39 260 -7 31 -48 29 -69
-3z m38 -67 c10 -40 7 -78 -8 -84 -8 -3 -26 -4 -40 -2 l-27 3 27 53 c30 58 39
63 48 30z"/>
<path d="M3740 2534 c-113 -7 -272 -16 -355 -20 -112 -5 -154 -11 -167 -22
-10 -8 -18 -18 -18 -23 0 -4 -17 -45 -39 -91 -21 -45 -63 -137 -94 -203 -30
-66 -71 -155 -91 -198 -20 -42 -36 -79 -36 -81 0 -2 -22 -52 -50 -110 -27 -58
-50 -109 -50 -112 0 -8 183 1 385 21 83 7 222 19 310 25 92 7 165 17 172 23
11 11 56 111 143 317 26 63 84 197 156 365 19 44 39 87 44 96 18 34 17 34
-310 13z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
